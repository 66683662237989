import React from 'react';
import '../../assets/css/loader.css'


const Loader = () => {
   
    return (
        <>
             <div class="site-preloader">
      
            </div> 
        </>
    );
};

export default Loader;