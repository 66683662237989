const firebaseConfig = {
    apiKey: "AIzaSyBHmFdKFCUBz-T8SMfxg5g--NbklgnuEbA",
    authDomain: "tic-limited.firebaseapp.com",
    projectId: "tic-limited",
    storageBucket: "tic-limited.appspot.com",
    messagingSenderId: "200880564159",
    appId: "1:200880564159:web:6e922652fccac26b67230a",
    measurementId: "G-14567DKEEX"
  };

export default firebaseConfig;