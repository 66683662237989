import React, { Fragment } from 'react'
import Header from '../component/layout/header'
import PageHeader from '../component/layout/pageheader'
import Footer from '../component/layout/footer'

export const TOSPage = () => {
  return (
    <Fragment>
            <Header />
            <PageHeader title={'Terms and Conditions'} curPage={'Terms and Conditions'} />
            <div className="tos-section padding-tb">
                <div className="container">  
                    <div className="row">
                        <h1>পরিষেবার শর্তাবলী</h1>
                        <h2>স্বাগতম</h2>
    
    <p>TIC Limited আইটি শিক্ষা প্রয়োজনীয়তার জন্য আমাদের চয়ন করার জন্য আপনাকে ধন্যবাদ। কোনও পরিষেবা ব্যবহারে পূর্বে অনুগ্রহ করে নিম্নলিখিত পরিষেবার শর্তাবলী সাবধানে পড়ুন এবং বুঝুন।</p>
    
    <h3>শর্তাবলীর গ্রহণ</h3>
    <p>TIC Limited প্রদত্ত যে কোনও পরিষেবা ব্যবহার করে বা অ্যাক্সেস করে, তারা আপনার এই পরিষেবার শর্তাবলীর বাধ্যতামূলক হতে সম্মত হতে হবে। আপনি যদি এই শর্তাবলীর সাথে সম্মত না হন তবে আমাদের পরিষেবা ব্যবহার করবেন না।</p>
    
    <h3>পরিষেবা</h3>
    <p>TIC Limited বিভিন্ন আইটি কোর্স, ওয়ার্কশপ, সেমিনার এবং অন্যান্য শিক্ষামূলক পরিষেবা প্রদান করে। প্রতিটি পরিষেবার বিশদ বর্ণনা বা নিবন্ধন মেটেরিয়ালে সুনির্দিষ্ট বিবরণ দেওয়া হবে।</p>
    
    <h3>নিবন্ধন</h3>
    <p>আমাদের কোনও কোর্স বা প্রোগ্রামে নিবন্ধন করতে, আপনার নিবন্ধন প্রক্রিয়া সম্পন্ন করতে এবং সঠিক এবং সম্পূর্ণ তথ্য সরবরাহ করতে হবে। নিবন্ধন করার মাধ্যমে, আপনি TIC Limited দ্বারা নির্ধারিত নিয়ম এবং বিধি মেনে চলার সম্মতি দেন।</p>
    
    <h3>অর্থ প্রদান</h3>
    <p>আমাদের পরিষেবার জন্য অর্থ প্রদান করতে আগ্রহী হলে কোর্স বা প্রোগ্রামের শুরুর আগে আপনাকে সম্পূর্ণ অর্থ প্রদান করতে হবে, যদি আপনি এটি বিশেষ করে উল্লেখ না করা হয়। আমরা [গ্রহণযোগ্য অর্থ প্রদানের পদ্ধতিগুলি] এবং সমস্ত ফি অন-রিফান্ডেবল।</p>
    
    <h3>বুদ্ধিমত্তা সম্পত্তি</h3>
    <p>সম্প্রতি, প্রেজেন্টেশন, হ্যান্ডআউট, সফটওয়্যার এবং অনলাইন সম্পদ সহ সমস্ত কোর্স মেটেরিয়াল TIC Limited এর বুদ্ধিমত্তা সম্পত্তি এবং এগুলি আপনার ব্যক্তিগত ব্যবহারের জন্য প্রদান করা হয়েছে। আপনি পূর্ব অনুমতি ছাড়া এই উপাদানগুলি পুনরুত্পাদন, বিতরণ বা সম্পাদন করতে পারবেন না।</p>
    
    <h3>আচরণের বিধি</h3>
    <p>আমরা সব ছাত্রছাত্রীদেরকে সর্বদা একটি পেশাদার এবং শ্রদ্ধাশীল পদ্ধতিতে আচরণ করার প্রত্যাশা করি। যে কোনও আচরণ যা শেখানি পরিবেশকে বিক্ষোভিত করে বা অন্যকে অধিকার লঙ্ঘন করে তা সহ্য করা হবে না এবং তা প্রশাসন প্রয়োগ করে প্রোগ্রাম থেকে বহিষ্কার করা হতে পারে অর্থাৎ ফি ফেরত পাওয়া যাবে না।</p>
    
    <h3>গোপনীয়তা নীতি</h3>
    <p>আমরা আপনার গোপনীয়তা সম্পর্কে শ্রদ্ধার্হ এবং তা রক্ষা করার জন্য প্রতিশ্রুতিবদ্ধ। আমাদের গোপনীয়তা নীতি আমরা কীভাবে আপনার তথ্য সংগ্রহ, ব্যবহার এবং ফাঁস করি, এবং আমাদের প্রয়োগগুলি সম্পর্কে ব্যাখ্যা করে। আমাদের পরিষেবা ব্যবহার করার মাধ্যমে, আপনি আমাদের গোপনীয়তা নীতির প্রয়োগগুলি অনুমোদন করেন।</p>
    
    <h3>দায়িত্বের সীমা</h3>
    <p>TIC Limited এ কোনও সরাসরি, প্রত্যক্ষ, অক্ষম, বিশেষ বা অনিষ্ক্রিয় ক্ষতির জন্য দায়ী নয়, আমাদের পরিষেবার ব্যবহার থেকে বা এর যে কোনও সম্পর্কে, একটি বাধ্যবাধক তত্ত্বে, চুক্তি, লেনদেন, অথবা কোনও অন্য ব্যবস্থাপন উপর ভিত্তি করে যেতে পারে।</p>
    
    <h3>শর্তাবলী পরিবর্তন</h3>
    <p>TIC Limited সত্ত্বেও সম্মতি না দিয়ে এই শর্তাবলী পরিবর্তন করার অধিকার রাখে। যে কোনও এই ধরনের পরিবর্তনের পরে আমাদের পরিষেবা ব্যবহার করতে থাকলে, আপনি নতুন শর্তাবলী অনুসরণ করার সম্মতি দিয়ে বাধ্য হবেন।</p>
    
    <h3>আইনপ্রিয়তা</h3>
    <p>এই শর্তাবলী আইনগত এবং আইনের প্রকারের সাথে সাজা করা হবে [আপনার এলাকায়], এটির অসংগতি নিয়ন্ত্রণ করা ছাড়া।</p>
    
    <p>আপনার যে কোনও প্রশ্ন বা আপত্তি থাকলে, অনুগ্রহ করে আমাদের সাথে যোগাযোগ করুন AH.Tower (5th Floor), Alokar More, Ghoramara, Boalia, Rajshahi।</p>
    
    <p>আপনার আইটি শিক্ষা প্রয়োজনীয়তার জন্য TIC Limited নির্বাচন করার জন্য ধন্যবাদ। আমরা আপনার লক্ষ্য অর্জনে সাহায্য করার আশা করছি!</p>
    
    <h3>TIC Limited</h3>
    <p>AH.Tower (5th Floor), Alokar More, Ghoramara, Boalia, Rajshahi</p>
    <p>https://ticlimited.com.bd</p>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
  )
}
